export const addToStorage = (name, value) => {
    localStorage.setItem(name, value);
};

export const getFromStorage = (name) => {
    return localStorage.getItem(name);
};

export const removeFromStorage = (name) => {
    return localStorage.removeItem(name);
};

export const addUserToStorage = (user) => {
    addToStorage("access_token", user.token);
    addToStorage("id_token", user.id_token);
    addToStorage("given_name", user.given_name);
    addToStorage("family_name", user.family_name);
};

export const getUserFromStorage = () => {
    return {
        token: getFromStorage("access_token"),
        id_token: getFromStorage("id_token"),
        given_name: getFromStorage("given_name"),
        family_name: getFromStorage("family_name"),
    };
};

export const removeUserFromStorage = () => {
    removeFromStorage("access_token");
    removeFromStorage("id_token");
    removeFromStorage("given_name");
    removeFromStorage("family_name");
};
