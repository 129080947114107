import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "../auth";
import Logo from "../assets/headerLogo.png";
import { ReactComponent as MenuLogo } from "../assets/menu.svg";
import { ReactComponent as MenuCloseLogo } from "../assets/close.svg";
import styled from "styled-components";
import { useWindowDimensions } from "../utils/general";
import {
    HEADER_MENU_BREAKPOINT,
    HEADER_MENU_BREAKPOINT_PX,
} from "../utils/constants";

const Header = () => {
    const { width } = useWindowDimensions();
    const { i18n } = useTranslation();
    const isMobile = width <= HEADER_MENU_BREAKPOINT;
    const [isOpen, setIsOpen] = React.useState(false);
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setIsOpen(false);
    };
    return (
        <StyledHeaderContainer isOpen={isOpen}>
            <StyledHeaderContent>
                <StyledHeaderPersistentContent>
                    <StyledTitleSection to="/">
                        <HeaderImage src={Logo} />
                        <span>Demo</span>
                    </StyledTitleSection>
                    {isMobile && (
                        <MenuIconWrapper onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? (
                                <MenuCloseLogo width="24px" height="24px" />
                            ) : (
                                <MenuLogo width="24px" height="24px" />
                            )}
                        </MenuIconWrapper>
                    )}
                </StyledHeaderPersistentContent>
                <NavSection changeLanguage={changeLanguage} isOpen={isOpen} />
            </StyledHeaderContent>
        </StyledHeaderContainer>
    );
};

export default Header;

const NavSection = ({ changeLanguage, isOpen }) => {
    const { t, i18n } = useTranslation();
    const auth = useAuth();
    let showLogout = true;
    if (auth === undefined || !auth.user) {
        showLogout = false;
    }
    return (
        <StyledNavSection isOpen={isOpen}>
            {!i18n.language.startsWith("fi") && (
                <NavItem>
                    <span onClick={() => changeLanguage("fi")}>Suomeksi</span>
                </NavItem>
            )}
            {!i18n.language.startsWith("en") && (
                <NavItem>
                    <span onClick={() => changeLanguage("en")}>In English</span>
                </NavItem>
            )}
            {showLogout && (
                <NavItem>
                    <StyledLogoutButton type="button" onClick={auth.logout}>
                        {t("Log out")}
                    </StyledLogoutButton>
                </NavItem>
            )}
        </StyledNavSection>
    );
};

const StyledHeaderContainer = styled.header`
    @media only screen and (min-width: 1980px) {
        font-size: 18px;
    }
    width: 100%;
    padding: 0 16px;
    background: ${(props) => props.theme.headerBackground};
    border-bottom-style: solid;
    border-bottom-width: ${(props) => props.theme.headerBorderWidth};
    border-bottom-color: ${(props) => props.theme.headerBorderColor};
    @media only screen and (max-width: 350px) {
        padding: 0 8px;
    }
    @media only screen and (max-width: ${HEADER_MENU_BREAKPOINT_PX}) {
        border-bottom-style: solid;
        border-bottom-width: ${(props) =>
            props.isOpen
                ? props.theme.headerMenuBorderWidth
                : props.theme.headerBorderWidth};
        border-bottom-color: ${(props) =>
            props.isOpen
                ? props.theme.headerMenuBorderColor
                : props.theme.headerBroderColor};
    }
`;

const StyledHeaderContent = styled.div`
    margin: 0 auto;
    height: 100%;
    max-width: 824px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${(props) => props.theme.headerShadow};
    @media only screen and (max-width: ${HEADER_MENU_BREAKPOINT_PX}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StyledHeaderPersistentContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const StyledTitleSection = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: ${(props) => props.theme.fontMedium};
    height: ${(props) => props.theme.headerHeight};
    * + * {
        margin-left: 12px;
    }
    @media only screen and (max-width: 350px) {
        * + * {
            margin-left: 8px;
        }
    }
`;

const StyledNavSection = styled.div`
    display: flex;
    height: 100%;
    white-space: nowrap;
    align-items: center;
    a {
        text-decoration: none;
        font-weight: ${(props) => props.theme.fontMedium};
    }
    * + * {
        margin-left: 32px;
    }
    @media only screen and (max-width: ${HEADER_MENU_BREAKPOINT_PX}) {
        display: ${(props) => (props.isOpen ? "flex" : "none")};
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0 24px 0;
        * + * {
            margin: 24px 0 0 0;
        }
    }
`;

const NavItem = styled.span`
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const MenuIconWrapper = styled.span`
    height: 24px;
    width: 24px;
    display: none;
    cursor: pointer;
    @media only screen and (max-width: ${HEADER_MENU_BREAKPOINT_PX}) {
        display: inline;
    }
`;

const HeaderImage = styled.img`
    height: 40%;
`;

const StyledLogoutButton = styled.button`
    font-weight: ${(props) => props.theme.fontMedium};
    border: none;
    -webkit-appearance: none;
    padding: 0;
    font-size: inherit;
    text-decoration: none;
    background-color: #fff;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
