import { getEnvVar } from "./general";

export const CLIENT_ID = getEnvVar("REACT_APP_CLIENT_ID");

export const HEADER_MENU_BREAKPOINT = 548;
export const HEADER_MENU_BREAKPOINT_PX = `${HEADER_MENU_BREAKPOINT}px`;

export const SCOPE = "openid profile embedded_wallet";
export const REDIRECT_URI = `${window.location.origin}/login`;
export const POST_LOGOUT_REDIRECT_URI = window.location.origin;
export const WALLET_URL = getEnvVar("REACT_APP_WALLET_URL");
export const AUTH_ITEM_NAME = getEnvVar("REACT_APP_AUTH_ITEM_NAME");

export const MDS_API_URL = getEnvVar("REACT_APP_MDS_API_URL");
export const API_URL = getEnvVar("REACT_APP_API_URL");
export const ENDPOINT_AUTH_ITEMS = "public/v3.0/auth_items";
export const ENDPOINT_USER = "user";
export const ENDPOINT_RECORD = "processing_record";
export const ENDPOINT_MDS_RECORD = "embedded_wallet/v3.0/processing_record";

export const RECORD_STATUS_ACTIVE = "active";
export const RECORD_STATUS_PENDING = "pending";
export const RECORD_STATUS_DECLINED = "declined";

export const RECORD_TYPE_CONSENT = "consent";
export const RECORD_TYPE_CONTRACT = "contract";
export const RECORD_TYPE_LEGAL_OBLIGATION = "legal_obligation";
export const RECORD_TYPE_LEGITIMATE_INTEREST = "legitimate_interest";
