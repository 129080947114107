import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { authorizationCallback, endSession } from "mydatashare-core";

import {
    addUserToStorage,
    getUserFromStorage,
    removeUserFromStorage,
} from "./utils/storage";
import client from "./utils/client";
import {
    CLIENT_ID,
    ENDPOINT_USER,
    POST_LOGOUT_REDIRECT_URI,
    REDIRECT_URI,
} from "./utils/constants";
import Loading from "./components/loading";

const AuthContext = React.createContext();

const fetchUser = async () => {
    const token = getUserFromStorage().token;
    let user = null;
    if (token) {
        user = { ...(await client(ENDPOINT_USER, { token })), token };
    }
    return user;
};

const LoginCallback = () => {
    const history = useHistory();
    const { refetch } = useQuery("user", fetchUser, { manual: true });
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        const refetchUser = async () => {
            await refetch();
            history.push("/");
        };

        authorizationCallback(CLIENT_ID, REDIRECT_URI)
            .then((result) => {
                setIsError(false);
                addUserToStorage({
                    token: result.accessToken,
                    id_token: result.idToken,
                });
                refetchUser();
            })
            .catch((error) => {
                console.error(
                    `Error occurred in authorizationCallback: ${error}`
                );
                setIsError(true);
            });
    }, [history, refetch]);

    if (isError) {
        throw new Error("Login failed");
    }

    return <Loading />;
};

/**
 * A Context provider for the authenticated user.
 *
 * Provide child components with the authenticated user. Display a loading screen while the user
 * information is being fetched. Provides two values: the current `user`, and a method for logging
 * out current user `logout`.
 *
 * Performs a fetch to the /user endpoint if access token is saved in storage.
 * If the /user endpoint response status code is 401, the user is logged out.
 * If the /user endpoint request fails for some other reason, and error is thrown.
 *
 * A possible alternative for this Context provider would be the ready made AuthenticationProvider
 * from @axa-fr/react-oidc-context.
 * @param {*} props
 */
const AuthProvider = (props) => {
    const history = useHistory();

    const { status, data: user } = useQuery("user", fetchUser);

    const logout = React.useCallback(() => {
        removeUserFromStorage();
        if (!endSession(POST_LOGOUT_REDIRECT_URI)) {
            window.location.assign("/");
        } else {
            history.push("/");
        }
    }, [history]);

    const value = React.useMemo(() => ({ user, logout }), [user, logout]);

    if (status === "loading" || status === "idle") {
        return <Loading withMainLayout={true} />;
    }

    if (status === "error") {
        throw new Error("Login failed");
    }

    if (status === "success") {
        return <AuthContext.Provider value={value} {...props} />;
    }
};

/**
 * Hook for accessing the authenticated user and logout method.
 *
 * Use in functional components like this: `const {user, logout} = useAuth();`.
 */
const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, LoginCallback, useAuth };
