import React from "react";

import Error from "./components/error";

/**
 * Error boundary for catching errors during the render phase.
 *
 * Displays an error page with the error message.
 *
 * If the prop `logOutUser` with value `true` is given, the user is logged out
 * after catching the error.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.logOutUser = props.logOutUser ? true : false;
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Error
                    message={this.state.error.message}
                    logOutUser={this.logOutUser}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
