import { createGlobalStyle } from "styled-components";

import staffLight from "./fonts/Staff-Light.woff";
import staffLight2 from "./fonts/Staff-Light.woff2";
import staffLightItalic from "./fonts/Staff-Light-Italic.woff";
import staffLightItalic2 from "./fonts/Staff-Light-Italic.woff2";
import staffRegular from "./fonts/Staff-Regular.woff";
import staffRegular2 from "./fonts/Staff-Regular.woff2";
import staffItalic from "./fonts/Staff-Italic.woff";
import staffItalic2 from "./fonts/Staff-Italic.woff2";
import staffMedium from "./fonts/Staff-Medium.woff";
import staffMedium2 from "./fonts/Staff-Medium.woff2";
import staffMediumItalic from "./fonts/Staff-Medium-Italic.woff";
import staffMediumItalic2 from "./fonts/Staff-Medium-Italic.woff2";
import staffSemiBold from "./fonts/Staff-SemiBold.woff";
import staffSemiBold2 from "./fonts/Staff-SemiBold.woff2";
import staffSemiBoldItalic from "./fonts/Staff-SemiBold-Italic.woff";
import staffSemiBoldItalic2 from "./fonts/Staff-SemiBold-Italic.woff2";
import staffBold from "./fonts/Staff-Bold.woff";
import staffBold2 from "./fonts/Staff-Bold.woff2";
import staffBoldItalic from "./fonts/Staff-Bold-Italic.woff";
import staffBoldItalic2 from "./fonts/Staff-Bold-Italic.woff2";
import staffBlack from "./fonts/Staff-Black.woff";
import staffBlack2 from "./fonts/Staff-Black.woff2";
import staffBlackItalic from "./fonts/Staff-Black-Italic.woff";
import staffBlackItalic2 from "./fonts/Staff-Black-Italic.woff2";

export const GlobalStyle = createGlobalStyle`
  
/* Light */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 300;
  src: url(${staffLight2}) format('woff2'),
     url(${staffLight}) format('woff');
  }

/* Light italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 300;
  src: url(${staffLightItalic2}) format('woff2'),
     url(${staffLightItalic}) format('woff');
}

/* Regular */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 400;
  src: url(${staffRegular2}) format('woff2'),
     url(${staffRegular}) format('woff');
}

/* Regular italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 400;
  src: url(${staffItalic2}) format('woff2'),
     url(${staffItalic}) format('woff');
}


/* Medium */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 500;
  src: url(${staffMedium2}) format('woff2'),
     url(${staffMedium}) format('woff');
}

/* Medium italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 500;
  src: url(${staffMediumItalic2}) format('woff2'),
     url(${staffMediumItalic}) format('woff');
}

/* Semi Bold */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 600;
  src: url(${staffSemiBold2}) format('woff2'),
     url(${staffSemiBold}) format('woff');
}

/* Semi Bold italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 600;
  src: url(${staffSemiBoldItalic2}) format('woff2'),
     url(${staffSemiBoldItalic}) format('woff');
}

/* Bold */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 700;
  src: url(${staffBold2}) format('woff2'),
     url(${staffBold}) format('woff');
}

/* Bold italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 700;
  src: url(${staffBoldItalic2}) format('woff2'),
     url(${staffBoldItalic}) format('woff');
}

/* Black */
@font-face {
  font-family: 'Staff';
  font-style: normal;
  font-weight: 900;
  src: url(${staffBlack2}) format('woff2'),
     url(${staffBlack}) format('woff');
}

/* Black italic */
@font-face {
  font-family: 'Staff';
  font-style: italic;
  font-weight: 900;
  src: url(${staffBlackItalic2}) format('woff2'),
      url(${staffBlackItalic}) format('woff');
}
  
html, body, #root {
  height: 100%;
  font-size: 16px;
}

html {
  box-sizing: border-box;
  line-height: 1.5;
}

*, *:before, *:after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: Staff, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.background};
}

a {
  @media only screen and (min-width: 1980px) {
    font-size: 1.125em;
  }
  color: ${(props) => props.theme.color};
}

h1 {
  font-size: 2em;
  @media only screen and (min-width: 1980px) {
    font-size: 2.25em;
  }
  @media only screen and (max-width: 421px) {
    font-size: 1.5em;
  }
}

h2 {
  vertical-align: center;
  font-weight: ${(props) => props.theme.fontBold};
  font-size: 1.375em;
  margin: 2.25em 0 1.125em 0;
  @media only screen and (min-width: 1980px) {
    font-size: 1.625em;
  }
@media only screen and (max-width: 421px) {
    font-size: 1.25em;
  }
}

p {
  @media only screen and (min-width: 1980px) {
    font-size: 1.125em;
  }
}

.btn {
  border: 0 none;
  padding: 0;
  margin: 0;
}

a[rel*="noopener"]::after {
  content: "\\00A0↗";
}

hr {
  height: 0.063em;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  outline: 0;
  clear: both;
  margin: calc(1.8em + .6vw) 0;
}
`;
