import {
    API_URL,
    ENDPOINT_AUTH_ITEMS,
    ENDPOINT_MDS_RECORD,
    ENDPOINT_RECORD,
    MDS_API_URL,
    RECORD_STATUS_ACTIVE,
} from "./constants";
import { removeUserFromStorage } from "./storage";

export const fetchAuthItems = async () => {
    return await client(ENDPOINT_AUTH_ITEMS, {
        method: "POST",
        baseUrl: MDS_API_URL,
    });
};

export const fetchRecord = async ({ queryKey }) => {
    return await client(ENDPOINT_RECORD, {
        token: queryKey[1].token,
        method: "POST",
    });
};

export const getRecordDataFromResponse = (response) => {
    if (!response || !response.processing_records || !response.data_consumers) {
        return [];
    }
    return Object.values(response.processing_records)
        .filter((record) => record.record_type !== "service_tos")
        .map((record) => {
            const consumer = response.data_consumers[record.data_consumer_uuid];
            const consumerOrg =
                response.organizations[consumer.organization_uuid];
            let provider = null;
            let providerOrg = null;
            if (record.data_provider_uuid && response.data_providers) {
                provider = response.data_providers[record.data_provider_uuid];
                providerOrg =
                    response.organizations[provider.organization_uuid];
            }
            return {
                record,
                consumer,
                consumerOrg,
                provider,
                providerOrg,
                metadatas: response.metadatas,
            };
        });
};

export const patchRecord = async ({ token, uuid, status, language }) => {
    const payload = { status };
    if (language) {
        payload.accepted_language = language;
    } else if (status === RECORD_STATUS_ACTIVE) {
        // Default to English if status === "active"
        payload.accepted_language = "eng";
    }
    return await client(`${ENDPOINT_MDS_RECORD}/${uuid}`, {
        token,
        method: "PATCH",
        baseUrl: MDS_API_URL,
        payload,
    });
};

/**
 * Make async calls to the backend API.
 *
 * If the request response status code is 401, the user is automatically logged out.
 *
 * Options:
 * - `method`: The http method to use, defaults to GET.
 * - `token`: If given, `token` is added to the `Authorization` header using the `Bearer` prefix.
 * - `payload`: Request body as an object. If given, `Content-Type` will  be set to `application/json`
 * - `headers`: Additional headers as an object.
 * - `config`: Additional fetch configurations as an object.
 * - `baseUrl`: The base URL to use for the endpoint, defaults to the Demo API.
 * @param {string} endpoint The endpoint to call, e.g. "/user"
 * @param {object} param1 Optional options and fetch configurations
 * @return {object} The response JSON content.
 */
const client = async (
    endpoint,
    { method = "GET", token, payload, headers, config, baseUrl } = {}
) => {
    const fetchConfig = {
        method,
        body: payload ? JSON.stringify(payload) : undefined,
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            "Content-Type": payload ? "application/json" : undefined,
            ...headers,
        },
        ...config,
    };

    const url = baseUrl ? `${baseUrl}/${endpoint}` : `${API_URL}/${endpoint}`;
    const res = await fetch(url, fetchConfig);
    if (res.status === 401) {
        console.log("Re-authentication needed, logging out user");
        removeUserFromStorage();
        window.location.assign(window.location);
        return Promise.reject({ message: "Authentication is needed" });
    }
    const res_data = await res.json();
    if (res.ok) return res_data;
    return Promise.reject(res_data);
};

export const handleQueryError = (
    isError,
    isSuccess,
    errorMessage = "errorQuery"
) => {
    if (isError || !isSuccess) {
        throw new Error(errorMessage);
    }
};

export default client;
