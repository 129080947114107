import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getLocalizedUrl } from "../utils/general";
import { WALLET_URL } from "../utils/constants";
import { ReactComponent as Logo } from "../assets/footerLogo.svg";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <StyledFooterWrapper>
            <StyledFooterInnerWrapper>
                <StyledFooterContent>
                    <StyledFooterTitleWrapper>
                        <Logo alt="Service logo" title="Service logo" />
                        <StyledFooterHeader>
                            {t("App title")}
                        </StyledFooterHeader>
                    </StyledFooterTitleWrapper>
                    <StyledFooterNavSection>
                        <StyledFooterItem>
                            <a
                                href={getLocalizedUrl(WALLET_URL)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("Wallet")}
                            </a>
                        </StyledFooterItem>
                        <StyledFooterItem>
                            <a
                                href="https://www.mydatashare.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                MyDataShare
                            </a>
                        </StyledFooterItem>
                        <StyledFooterItem>
                            <a
                                href={getLocalizedUrl(
                                    `${WALLET_URL}/ext/terms`
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("Terms of Service")}
                            </a>
                        </StyledFooterItem>
                        <StyledFooterItem>
                            <a
                                href={getLocalizedUrl(
                                    `${WALLET_URL}/ext/privacy`
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("Privacy Statement")}
                            </a>
                        </StyledFooterItem>
                    </StyledFooterNavSection>
                </StyledFooterContent>
            </StyledFooterInnerWrapper>
        </StyledFooterWrapper>
    );
};

export default Footer;

const StyledFooterWrapper = styled.footer`
    margin: 64px 0 auto auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledFooterInnerWrapper = styled.div`
    width: 100%;
    padding: 48px 24px 64px 24px;
    background-color: ${(props) => props.theme.footerBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledFooterContent = styled.div`
    color: ${(props) => props.theme.footerColor};
    width: 100%;
    max-width: 824px;
`;

const StyledFooterTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media only screen and (max-width: 421px) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

const StyledFooterHeader = styled.h2`
    margin: 0 0 0 16px;
    font-size: 16px;
    @media only screen and (min-width: 1980px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 421px) {
        margin: 16px 0 0 0;
    }
`;

const StyledFooterNavSection = styled.nav`
    display: flex;
    flex-wrap: wrap;
    font-weight: ${(props) => props.theme.fontRegular};
    margin-top: 12px;
    margin: 12px -16px 0 -16px;
    a {
        text-decoration: none;
    }
    span:hover {
        text-decoration: underline;
    }
    @media only screen and (max-width: 421px) {
        flex-direction: column;
        margin: 24px -16px 0 -16px;
    }
`;

const StyledFooterItem = styled.span`
    margin: 16px 16px 0 16px;
`;
