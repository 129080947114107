import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { removeUserFromStorage } from "../utils/storage";
import MainLayout from "./main-layout";

const Error = ({ message, logOutUser = false }) => {
    const { t } = useTranslation();

    if (logOutUser) {
        console.log("Logging out user");
        removeUserFromStorage();
    }

    return (
        <MainLayout>
            <StyledWrapper>
                <h1>{t("Error header")}</h1>
                {message && <p>{t(message)}</p>}
                <a href="/">{t("Back home")}</a>
            </StyledWrapper>
        </MainLayout>
    );
};

export default Error;

const StyledWrapper = styled.div`
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
`;
