import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import Footer from "./footer";
import Header from "./header";
import { HEADER_MENU_BREAKPOINT_PX } from "../utils/constants";

const MainLayout = ({ children }) => {
    const themeContext = useContext(ThemeContext);
    return (
        <StyledRoot>
            <Header />
            <StyledContent id="main-content">
                <StyledMainWrapper>
                    <StyledContentOuterWrapper>
                        <StyledContentInnerWrapper>
                            {children}
                        </StyledContentInnerWrapper>
                    </StyledContentOuterWrapper>
                    <Footer footerStyle={themeContext.footerStyle} />
                </StyledMainWrapper>
            </StyledContent>
        </StyledRoot>
    );
};

export default MainLayout;

const StyledRoot = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledContent = styled.main`
    width: 100%;
    height: 100%;
    min-width: 294px;
    justify-self: center;
`;

const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const StyledContentOuterWrapper = styled.div`
    max-width: 856px;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
`;

const StyledContentInnerWrapper = styled.div`
    margin: 32px 16px 16px 16px;
    justify-self: center;
    @media only screen and (min-width: 1980px) {
        max-width: 1152px;
    }
    @media only screen and (max-width: ${HEADER_MENU_BREAKPOINT_PX}) {
        margin: 16px;
    }
`;
