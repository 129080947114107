import i18next from "i18next";
import { getTranslation, LANGUAGES } from "mydatashare-core";
import React from "react";
import { ReactComponent as ActiveIcon } from "../assets/check-circle-light.svg";
import { ReactComponent as DeclinedIcon } from "../assets/ban-light.svg";
import { ReactComponent as DeletedIcon } from "../assets/trash-light.svg";
import { ReactComponent as DeprecatedIcon } from "../assets/exclamation-circle-light.svg";
import { ReactComponent as ExpiredIcon } from "../assets/calendar-exclamation-light.svg";
import { ReactComponent as PendingIcon } from "../assets/hourglass-regular.svg";
import { ReactComponent as RevokedIcon } from "../assets/times-circle-light.svg";
import { ReactComponent as WithdrawnIcon } from "../assets/archive-light.svg";
import { ReactComponent as ConsentIcon } from "../assets/handshake-light.svg";
import { ReactComponent as ContractIcon } from "../assets/file-signature-light.svg";
import { ReactComponent as LegalObligationIcon } from "../assets/balance-scale-light.svg";
import { ReactComponent as LegitimateInterestIcon } from "../assets/building-light.svg";
import { ReactComponent as PublicTaskIcon } from "../assets/landmark-light.svg";
import { ReactComponent as VitalInterestIcon } from "../assets/heartbeat-light.svg";

export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export const getRecordStatusIcon = (status, props = {}) => {
    const RECORD_STATUS_ICONS = {
        active: <ActiveIcon {...props} />,
        declined: <DeclinedIcon {...props} />,
        deleted: <DeletedIcon {...props} />,
        deprecated: <DeprecatedIcon {...props} />,
        expired: <ExpiredIcon {...props} />,
        pending: <PendingIcon {...props} />,
        revoked: <RevokedIcon {...props} />,
        terminated: <DeprecatedIcon {...props} />,
        withdrawn: <WithdrawnIcon {...props} />,
    };
    if (!(status in RECORD_STATUS_ICONS)) return RECORD_STATUS_ICONS.terminated;
    return RECORD_STATUS_ICONS[status];
};

export const getRecordTypeIcon = (type, props = {}) => {
    const RECORD_TYPE_ICONS = {
        consent: <ConsentIcon {...props} />,
        contract: <ContractIcon {...props} />,
        legal_obligation: <LegalObligationIcon {...props} />,
        legitimate_interest: <LegitimateInterestIcon {...props} />,
        public_task: <PublicTaskIcon {...props} />,
        vital_interest: <VitalInterestIcon {...props} />,
    };
    if (!(type in RECORD_TYPE_ICONS)) return RECORD_TYPE_ICONS.consent;
    return RECORD_TYPE_ICONS[type];
};

export const getLocalizedUrl = (urlString) => {
    const url = new URL(urlString);
    url.searchParams.append("lng", i18next.language);
    return url.toString();
};

export const translate = (obj, field, metadatas) => {
    return getTranslation(obj, field, LANGUAGES[i18next.language], metadatas);
};

export const getEnvVar = (key) => {
    // eslint-disable-next-line no-underscore-dangle
    if (window._env_[key]) return window._env_[key];
    return process.env[key];
};

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );
    React.useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
};
