import en from "./en.json";
import fi from "./fi.json";

const translations = {
    en: {
        translation: en,
    },
    fi: {
        translation: fi,
    },
};

export default translations;
