import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { AuthProvider } from "./auth";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: false, // Loading state is handled in code
            useErrorBoundary: false, // Errors are thrown to the boundary in code
            staleTime: 5000, // Queries become stale after 5 seconds
        },
    },
});

const Providers = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>{children}</AuthProvider>
        </QueryClientProvider>
    );
};

export default Providers;
