import React from "react";
import styled from "styled-components";

import MainLayout from "./main-layout";

const Loading = ({ withMainLayout = false }) => {
    if (!withMainLayout) {
        return (
            <StyledContainer>
                <StyledFlashingDot />
            </StyledContainer>
        );
    }
    return (
        <MainLayout>
            <StyledContainer>
                <StyledFlashingDot />
            </StyledContainer>
        </MainLayout>
    );
};

export default Loading;

const commonStyles = (props) => `
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props.theme.loadingColor};
    color: ${props.theme.loadingColor};
`;

const StyledFlashingDot = styled.div.attrs({
    className: "loading-animation",
})`
    position: relative;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    ${(props) => commonStyles(props)};

    ::before,
    ::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        ${(props) => commonStyles(props)};
    }

    ::before {
        left: -15px;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
    }

    ::after {
        left: 15px;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes dotFlashing {
        0% {
            background-color: ${(props) => props.theme.loadingColor};
        }
        50%,
        100% {
            background-color: ${(props) => props.theme.loadingSecondaryColor};
        }
    }
`;

const StyledContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    margin: 20vh 0;
`;
