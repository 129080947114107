import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./i18n";
import Providers from "./providers";
import Loading from "./components/loading";
import ErrorBoundary from "./error-boundary";
import theme from "./theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./theme/global-style";

const App = React.lazy(() => import("./app"));

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router>
                <ErrorBoundary logOutUser={true}>
                    <Providers>
                        <ErrorBoundary logOutUser={false}>
                            <Suspense
                                fallback={<Loading withMainLayout={true} />}
                            >
                                <App />
                            </Suspense>
                        </ErrorBoundary>
                    </Providers>
                </ErrorBoundary>
            </Router>
            <GlobalStyle />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
